export const TEAM_DATA = [
  {
    name: 'Alex Copperman',
    title: 'Technical Support Representative',
    bio: 'Alex joined the support team at Opentrons in 2020. While completing his degree in Engineering Science at The University of Rochester, he completed internships at Oova Life Sciences and TMRW Life Sciences where he assisted with product development and market analysis. Outside of work, Alex can be found singing show tunes, doing yoga, reading a book, and making homemade ravioli.',
  },
  {
    name: 'Alfie Umbhau',
    title: 'VP of Product',
    img: require('./assets/alfie.png').default,
    bio: "As VP of Product at Opentrons, Alfie is incredibly excited to build the next generation of people-friendly lab robots. Prior to Opentrons, Alfie was a product leader at OnDeck from startup phase to IPO, where he helped build and scale the first small business online lending platform — and learned a thing or two about turning complex systems into intuitive experiences. When he's not reading up on bio and robotics, you can find him out in the mountains above and beyond the city.",
  },
  {
    name: 'Will Canine',
    title: 'Co-Founder and CPO',
    img: require('./assets/will.png').default,
    bio: 'Will comes from a background in community organizing and political campaign management, but decided to pursue technology as a more effective way to change the world. Obsessed with microbiology since an early age, Will became interested in open-source lab automation as a lever for accelerating scientific research while working at Genspace during his master’s degree at NYU’s Interactive Telecommunications Program in 2014. When not working to make lab robots for everyone, he likes to read books (always physical copies, particularly sci-fi and evolutionary theory) and go for long runs.',
  },
  {
    name: 'Alise Au',
    title: 'Software Engineer',
    img: require('./assets/alise.png').default,
    bio: `Prior to joining Opentrons in 2018, Alise worked in a social behavioral research lab at NYU's Neuroscience Institute. She graduated with a BS in Biomedical Engineering from Binghamton University. In her free time, she enjoys film photography, tango dancing, and attempting to train her two cats and keep her indoor plants alive.`,
  },
  {
    name: 'Amanda Lee Hawkins',
    title: 'Customer Success Associate',
    bio: 'Coming soon!',
  },
  {
    name: 'Amit Lissack',
    title: 'Senior Software Engineer',
    bio: 'Coming soon!',
  },
  {
    name: 'Anthony Chou',
    title: 'R&D Intern',
    bio: 'Coming soon!',
  },
  {
    name: 'August Koehler',
    title: 'R&D Intern',
    bio: 'Coming soon!',
  },
  {
    name: 'Aydan Aktug',
    title: 'Account Manager',
    img: require('./assets/aydan.png').default,
    bio: `Aydan is a part of the Account Management team. Prior to joining Opentrons in January 2020, she worked in a variety of biotech and software companies, with a passion for connecting products with users who love them and the areas in which beauty and functionality intersect. Aydan has a Bachelors of Science degree in Bioengineering from Lehigh University. Outside of work, she is either practicing yoga, watching sci-fi movies, or gallivanting around New York City.`,
  },
  {
    name: 'Nusrat Begum',
    title: 'QA Engineer',
    img: require('./assets/nusrat.png').default,
    bio: `Nusrat joined Opentrons in January 2017, as a quality assurance intern. She received her Bachelor Degree in Chemical Engineering from the City College of New York and has experience in Water Supply Engineering for the greater nyc area. When Nusrat is not at Opentrons, she can be found exploring the numerous NYC restaurants and cafes.`,
  },
  {
    name: 'Jethary Rader',
    title: 'Senior Research Technician',
    img: require('./assets/jethary.png').default,
    bio: `Jethary joined Opentrons in 2019 after working in an evolutionary genomics lab at Princeton University. Jethary earned a biology degree in 2018 at Reed College and wrote their undergrad thesis on the pathogenicity of enteropathogenic <i>Escherichia coli</i>. They enjoy playing piano, traveling, taking photos, and exploring restaurants and bars in the city.`,
  },
  {
    name: 'Jason Friske',
    title: 'Senior Controller',
    bio: 'Coming soon!',
  },
  {
    name: 'Joycephine Li',
    title: 'Jr R&D Engineer',
    bio: 'Coming soon!',
  },
  {
    name: 'David Gomez',
    title: 'Staff Electrical Engineer',
    img: require('./assets/david_g.png').default,
    bio: `David joined Opentrons in 2019 carrying experience in hardware product development fostered at companies like Fitbit and Sonos. He hopes to use his passion for rigorous engineering to create elegant, reliable, time saving machines that scientist can trust with their most critical experiments. David graduated with a BS in Electrical Engineering from MIT. Aside from engineering, David enjoys creating light art, playing hockey, fostering kittens, and listening to music, especially funk.`,
  },
  {
    name: 'Shlok Amin',
    title: 'Software Engineer',
    img: require('./assets/shlok.png').default,
    bio: `Shlok joined the software team at Opentrons in 2019. After graduating with a computer science degree from Northwestern University, he went on to work as a software development consultant for ThoughtWorks, where he studied the art of agile software delivery. In his free time he enjoys running, rock climbing, playing soccer, and exploring New York City.`,
  },
  {
    name: 'Rae Noyes',
    title: 'HR Manager',
    bio: 'Coming soon!',
  },
  {
    name: 'Raymond Gochuico',
    title: 'Sales Development Representative',
    img: require('./assets/ray.png').default,
    bio: `Ray came to Opentrons in August 2019 after completing his Biology Degree at at Binghamton University. Previously, Ray co-founded a vertical farming start-up and is excited to bring the tools he learned to his growing team. In his free time, Ray enjoys the outdoors, sports, and gardening.`,
  },
  {
    name: 'Rich Roser',
    title: 'Senior Mechanical Engineer',
    bio: 'Coming soon!',
  },
  {
    name: 'Carlos Fernandez',
    title: 'Systems Testing Engineer',
    img: require('./assets/carlos.png').default,
    bio: `Carlos joined Opentrons in November after having a progressive career at Makerbot. He's passionate in testing machine subsystems, building test fixtures, and understanding the behavior of robots. He's currently working as the systems testing engineer, focuses on understanding the subsystems of the OT machines and making sure they perform reliability. Outside of work, Carlos enjoys designing,3D printing and programming small projects.`,
  },
  {
    name: 'Max Marrone',
    title: 'Software Engineer',
    img: require('./assets/max.png').default,
    bio: `Max joined Opentrons in 2019 after earning a computer science degree from NYU Tandon. He brings to Opentrons a passion for making technology work better for humans. His other activities include writing software, tinkering with electronics, playing drums, and flying airplanes.`,
  },
  {
    name: 'Chaz Childers',
    title: 'Applications Engineer',
    img: require('./assets/chaz.png').default,
    bio: 'Chaz joined Opentrons in August 2019 as a Jr. Applications Engineer to help scientists translate their protocols for use on the OT-2 robot. Prior to joining Opentrons, Chaz led growth hacking at a health-tech start-up in NYC. In his free time, Chaz enjoys music, movies, restaurants, and following Portland (OR) sports',
  },
  {
    name: 'Andy Calderon',
    title: 'Senior Electrical Engineer',
    img: require('./assets/andy-c.png').default,
    bio: 'Andy enjoys creating great hardware. Andy love foosball, ramen, his dog, lakes, and electronics. Andy has never missed seeing a Fast and the Furious movie in theaters.',
  },
  {
    name: 'Annika Yasuf',
    title: 'Senior Research Technician',
    img: require('./assets/annika.png').default,
    bio: 'Annika joined our team in 2019 as our Lab Technician. Before joining Opentrons she worked for the Office of Chief Medical Examiner as a Criminalist. She received her Master’s Degree in Medical Microbiology from Kingston University in London. She has been to over 30 countries and when she isn’t travelling, she enjoys cooking and baking.',
  },
  {
    name: 'Anthony Sogluizzo',
    title: 'Senior Product Manager',
    bio: 'Coming soon!',
  },
  {
    name: 'Anthony Wille',
    title: 'Senior Product Manager',
    bio: 'Coming soon!',
  },
  {
    name: 'Ayla Decavallas',
    title: 'Applications Sales Lead',
    img: require('./assets/ayla.png').default,
    bio: 'Ayla joined our team as an Inside Sales Lead in 2018. Before joining Opentrons, Ayla worked as a research fellow at the NIH studying Fragile X Syndrome. She received a degree in Biomolecular Science from NYU. Outside of work Ayla can be found volunteering, playing board games or watching musical comedies.',
  },
  {
    name: 'Ben Donohue',
    title: 'VP of Software',
    img: require('./assets/ben_d.png').default,
    bio: 'Ben leads software development at Opentrons. Prior to coming onboard, he helped guide teams through growth and rapid product delivery at disruptive technology startups across a number of industries (fintech, music, advertising, and threat intelligence). Ben graduated from St. Lawrence University with a combined BA in Environmental Studies and Philosophy. Outside of work, his interests trend towards activities involving the outdoors, music and/or food.',
  },
  {
    name: 'Benjamin Minich',
    title: 'Test Engineering Manager',
    bio: 'Coming soon!',
  },
  {
    name: 'Boris Mindzak',
    title: 'Internal Counsel',
    bio: 'Coming soon!',
  },
  {
    name: 'Brian Cooper',
    title: 'Senior Software Engineer',
    img: require('./assets/brian.png').default,
    bio: 'Brian joined Opentrons in June 2018, and works across the software stack with an emphasis on the client side. After working in evolutionary genomics to synthesize and visualize data, he gained experience building software at a start-up alcohol delivery app, and is excited to contribute to the world of biological research as part of the team at Opentrons. Brian has a bachelor’s degree in Bioinformatics and Music, and outside of work he writes and performs music, and climbs rocks.',
  },
  {
    name: 'Chiu Chau',
    title: 'Co-Founder and CTO',
    img: require('./assets/chiu.png').default,
    bio: 'Chiu worked in biotech for 20+ years before building the first Opentrons robot. In his previous company (bought by Immucor in 2008) he led the engineering team to build the core multiplex qPCR automation technology now used in most blood banks for advanced blood typing. Chiu grew up in Hong Kong and is a diehard raquetball player.',
  },
  {
    name: 'Chris Yarka',
    title: 'Senior Mechanical Engineer',
    img: require('./assets/chris.png').default,
    bio: 'Before joining Opentrons in January 2019, Chris was working at MakerBot designing new 3D printers. He enjoys developing new automation products, traveling, spending time with friends, and spinning.',
  },
  {
    name: 'Christopher Farace',
    title: 'CRM Manager',
    bio: 'Coming soon!',
  },
  {
    name: 'Danny Smith',
    title: 'Drafter & Sustaining Engineer',
    img: require('./assets/danny.png').default,
    bio: 'Danny leads the sustaining and engineering documentation efforts at Opentrons. Before coming to Opentrons in January of 2018, he spent several years working in the 3D printing industry, where he performed a variety of roles ranging from technical support to mechanical engineering. Danny received his BS in Mechanical Engineering from NYU Tandon. He enjoys spending his free time playing tabletop games and tinkering with new cocktail recipes.',
  },
  {
    name: 'Dani Gross',
    title: 'SDR Manager',
    img: require('./assets/dani.png').default,
    bio: 'Coming soon!',
  },
  {
    name: 'David Kim',
    title: 'Director of Operations',
    img: require('./assets/david.png').default,
    bio: "David partners with the Opentrons hardware team to make lab automation products more accessible and easier to use for all biologists. Previously, David worked at MakerBot, where he led the hardware product team to build next generation 3D printers. When not making robots, you're likely to find him summiting another Adirondack High Peak, traveling to discover new foods or taking his dog to off-leash at Prospect Park.",
  },
  {
    name: 'Ethan Jones',
    title: 'Technical Support Manager',
    img: require('./assets/ethan.png').default,
    bio: "Ethan joined the Opentrons family in November 2018. By working as a technical support representative, Ethan plays an integral role in defining Opentrons' technical support standards. Additionally, Ethan uses his previous technical experience to field questions. Through his work, Ethan aims to enhance customer's experience with their robots. Ethan obtained his BS in Chemistry from King's College in 2015. In his free time, he enjoys taking his dog Dempster to the park, as well as exploring the science of fermented foods.",
  },
  {
    name: 'Emily Wolfe',
    title: 'Lead UX Designer',
    bio: 'Coming soon!',
  },
  {
    name: 'Eric Poley',
    title: 'SVP of Strategic Sales',
    bio: 'Coming soon!',
  },
  {
    name: 'Esther Ehrenman',
    title: 'E-Commerce Product Manager',
    bio: 'Coming soon!',
  },
  {
    name: 'Evan Surrick',
    title: 'Sales Development Representative',
    bio: 'Coming soon!',
  },
  {
    name: 'Gina Bargiachi',
    title: 'Fulfillment Coordinator',
    bio: 'Coming soon!',
  },
  {
    name: 'Greg Brown',
    title: 'VP of Sales',
    bio: 'Coming soon!',
  },
  {
    name: 'Greg Greeley',
    title: 'COO',
    bio: 'Coming soon!',
  },
  {
    name: 'Gylianne Tyndall',
    title: 'Technical Support Representative',
    bio: 'Coming soon!',
  },
  {
    name: 'Ian London',
    title: 'Senior Software Engineer',
    img: require('./assets/ian.png').default,
    bio: 'While working in yeast and fly labs, Ian experimented with building software to automate the repetitive, error-prone parts of his laboratory benchwork. After gaining more experience in software engineering as a contractor, he came to Opentrons where he hopes to contribute to the revolution in open and automated science by making it easy and intuitive for scientists to automate and streamline their research.',
  },
  {
    name: 'Jackson Seidenberg',
    title: 'Industrial Designer',
    img: require('./assets/jackson.png').default,
    bio: 'Jackson is the Industrial Designer on the hardware team at Opentrons. While earning his BFA in Industrial Design at the Rhode Island School of Design, he found an interest in the connections between the technical and user experience sides of product design. Starting his career at a design firm, he soon found himself working for a 3D Printing company, designing some of the first award winning consumer 3D printers. After work, Jackson spends his free time playing guitar, seeing live music, and figure drawing.',
  },
  {
    name: 'John Frelinger',
    title: 'Sales Executive',
    bio: 'Coming soon!',
  },
  {
    name: 'Jonathan Badal',
    title: 'CEO',
    img: require('./assets/jon.png').default,
    bio: "Jon leads Opentrons' business operations and growth. Jon is also a Board Member at Genspace and has been an advisor to Opentrons since the company's beginning. Jon joins us from Amazon, where he was the VP of Strategy at comiXology (exited to Amazon in 2014). As an early employee at comiXology, he helped the company become the leading platform for discovering, buying, and reading digital comic books and #1 Top Grossing non-game iPad app. Prior to comiXology, Jon was an analyst at early-stage VC, Rose Tech Ventures, and graduated from Columbia Unversity as a History major.",
  },
  {
    name: 'Jonathan Kahnt',
    title: 'Operations Associate',
    img: require('./assets/jpk.png').default,
    bio: 'Jonathan joined the Opentrons team as a Warehouse Associate in early October of 2018. He graduated from Shenandoah Conservatory with a BFA in Music. When he’s not busy shipping robots and pipettes, he plays bass in the band Mount Sharp. He also spends far too much time reading comic books.',
  },
  {
    name: 'Jonathan Martinez',
    title: 'Senior Director of Sales',
    img: require('./assets/jon-m.png').default,
    bio: 'Jon leads Sales at Opentrons. Before joining Opentrons Jon worked in a variety of sales roles in the healthcare and pharmaceutical space and has experience both selling to research oriented folks as well as leading teams that have successfully done so. Jon initially aimed to become a lawyer but found his true calling was working to help accelerate the pace of innovation in the scientific world. Jon holds a degree from Binghamton University. In his free time he can be found watching the Food Network for his next favorite recipe, eating at a sushi bar, or on the basketball court.',
  },
  {
    name: 'Joslyn Yeager',
    title: 'HR Coordinator',
    bio: 'Coming soon!',
  },
  {
    name: 'Katie Adee',
    title: 'Senior Software Engineer',
    img: require('./assets/katie.png').default,
    bio: 'Since joining Opentrons in June 2015, Pratt School of Architecture and NYU ITP alum Katie Adee has worked on most things software-related at the company -- everything from website development and design to core technology R&D -- lately, her main focus has been user interface development. When she’s not at Opentrons or teaching at NYU Tandon School of Engineering, Katie can be found cycling, shooting recurve bows, and helping out on farms near her North Fork, Long Island, home. (Pro tip: ask Katie for restaurant recommendations)',
  },
  {
    name: 'Kevan Savage',
    title: 'VP Marketing, Robotics',
    bio: 'Coming soon!',
  },
  {
    name: 'Kinnari Watson',
    title: 'Director of Science',
    img: require('./assets/kinnari.png').default,
    bio: 'Kinnari joined Opentrons in 2018 as a Staff Scientist to automate experiments for broad applications in Next Generation Sequencing pipelines. In 2012, she earned her BS in Biology from Spelman College. Recently, she completed her PhD thesis in the Molecular Biology Department at Princeton University where she studied yeast genomics and modeled DNA mismatch repair pathways in <em>Saccharomyces cerevisiae</em>. When she is not eagerly automating molecular biology techniques, she can be found wherever there is good food, music and beer.',
  },
  {
    name: 'Laura Cox',
    title: 'Software Engineer',
    img: require('./assets/laura.png').default,
    bio: 'Laura joined Opentrons in September 2017 after graduating from Georgia Tech with a BS in Biomedical Engineering and minor in Computer Science. She is currently working as the Protocol Developer to help customers personalize the OT robot to fit their research needs. In her free time, Laura enjoys tutoring, playing with her two cats and reading.',
  },
  {
    name: 'Lissette Bouza',
    title: 'Account Manager',
    bio: 'Coming soon!',
  },
  {
    name: 'Laurie Vazquez',
    title: 'Content Marketing Manager',
    img: require('./assets/laurie.png').default,
    bio: 'Laurie joined our team in April 2019. Before Opentrons, she was the Communications Manager for Stevens Institute of Technology’s Schaefer School of Engineering and Science, and a freelance science writer. She has a Bachelor of Fine Arts in Dramatic Writing from NYU, and outside of work Laurie is a best-selling author, optioned screenwriter, and violist.',
  },
  {
    name: 'Luca Scarano',
    title: 'Staff Mechanical Engineer',
    img: require('./assets/luca.png').default,
    bio: "Luca is an accomplished engineer with a passion for mechanical design. Building and creating mechanical systems was sparked at a young age from playing with Legos and working on various projects with his father. Luca joined the Opentron's team in November 2016 as an intern. Prior to Opentrons, Luca completed his Bachelor's Degree in mechanical engineering where he was also president of the Mechanical Club. When Luca is not at Opentrons, he is usually outside driving his vintage Alfa Romeo (or fixing it), mountain biking or swimming in the Long Island sound.",
  },
  {
    name: 'Madelyn Heart',
    title: 'Junior Account Manager',
    img: require('./assets/madelyn.png').default,
    bio: `Madelyn joined Opentrons in December 2019. She graduated from Bates College with a BA in Biology and spent a year teaching biology in Madrid, Spain. Madelyn’s interests include traveling, being outside, and finding the best rooftop view in New York City.`,
  },
  {
    name: 'Maddy Kelly',
    title: 'Director of Customer Success',
    img: require('./assets/maddy.png').default,
    bio: 'Maddy joined Opentrons as Account Manager in October 2018. She holds her MA in Biotechnology from Columbia University & BS in Human & Organizational Development from Vanderbilt University. Maddy’s interests include live music, NYC parks, mental health advocacy, and her puppy, Mezcal.',
  },
  {
    name: 'Matt Peterfreund',
    title: 'Applications Sales Lead',
    img: require('./assets/matt_p.png').default,
    bio: "Matt joined Opentrons as an Inbound Sales Lead in March of 2019. After graduating from Hamilton College with Bachelor's in Biology, Matt set out across the world working and traveling across South America and Africa. In his free time Matt can be found hanging out with his dogs, playing guitar, and finding excuses to escape the city.",
  },
  {
    name: 'Matthew Monsees',
    title: 'Senior Performance Marketing Manager',
    img: require('./assets/matt.png').default,
    bio: "Matt joined Opentrons as Senior Performance Marketing Manager in 2018 hoping to play international matchmaker between Opentrons and sufferers of endless pipetting. After ten years in NYC he's beginning to feel like he's more from the city than from his hometown Asheville, NC, and when he's not testing ways to drive incremental revenue you'll probably find him outdoors (think biking, surfing, kayaking, camping, etc...) or on long drives to see friends and family.",
  },
  {
    name: 'Matt Welch',
    title: 'Senior Product Manager',
    bio: 'Coming soon!',
  },
  {
    name: 'Matt Zwimpfer',
    title: 'Technical Support Engineer',
    bio: 'Coming soon!',
  },
  {
    name: 'Mehdi Zaidi',
    title: 'Technical Support Engineer',
    img: require('./assets/mehdi.png').default,
    bio: 'Mehdi joined Opentrons as a Technical Support Representative in November 2018. He attended the University of Texas at Dallas and received a BS in Biomedical Engineering. Before working at Opentrons, Mehdi spent time at a biomedical engineering and neuroscience research lab that focused on vagus nerve stimulation therapy. His interest include staying active, messing with microcontrollers, and following Dallas sports teams.',
  },
  {
    name: 'Michael Asham',
    title: 'Director of Application Sciences & Solutions',
    bio: 'Coming soon!',
  },
  {
    name: 'Mike Cousins',
    title: 'Senior Software Engineer',
    img: require('./assets/mike.png').default,
    bio: "Mike works on Opentrons’ internet stuff you can click. After graduating as a mechanical engineer concentrating in mechatronics, he has worked designing hardware and firmware for wearables and writing full-stack software at a connected home startup. Mike's greatest fears are heights and accidentally publishing breaking changes under a minor bump. His hobbies include consuming caffeine, writing open-source code, and explaining his own jokes.",
  },
  {
    name: 'Mike Gaffney',
    title: 'SVP of Sales, Robotics',
    bio: 'Coming soon!',
  },
  {
    name: 'Morgan Seiler',
    title: 'UX Designer',
    img: require('./assets/morgan.png').default,
    bio: "Morgan joined in Nov 2017 to do research and design for stuff that users experience. Before that she got her Bachelor's Degree from NYU in neuroscience & bioethics, and did ux for an influencer marketing platform. In free time she makes figurines, enjoys irredeemably terrible movies, and tries to talk herself into going back to fencing class.",
  },
  {
    name: 'Neha Ojha',
    title: 'Senior Software Development Engineer in Test',
    bio: 'Coming soon!',
  },
  {
    name: 'Nick Diehl',
    title: 'Applications Engineer',
    img: require('./assets/nick_d.png').default,
    bio: 'Nick joined the Opentrons team in February 2019 after receiving a BE in Biomedical Engineering from Vanderbilt University in 2018. He uses his background in lab biology, computer science, and math to develop custom biology protocols for scientists around the world. In his free time, Nick enjoys traveling, playing guitar, playing baseball, going to the beach, cooking, and eating his way around New York City.',
  },
  {
    name: 'Nick Starno',
    title: 'Senior Mechanical Engineer',
    bio: 'Coming soon!',
  },
  {
    name: 'Nick Yonts',
    title: 'Senior Applications Sales Lead',
    bio: 'Coming soon!',
  },
  {
    name: 'Ojas Patel',
    title: 'Jr Testing Engineer',
    img: require('./assets/ojas.png').default,
    bio: 'Ojas joined Opentrons in August 2018 and quickly grew a passion for getting customers up and running with the OT-2. With a background in creative writing, insurance, and tech-focused interdisciplinary research, he brings a broad range of skills to the job. In his free time, he enjoys playing videogames, making music, writing/telling stories, and reading poetry on the train.',
  },
  {
    name: 'Pantea Razzaghi',
    title: 'Director of UX Design',
    img: require('./assets/pantea.png').default,
    bio: 'Pantea leads user experience research and design at Opentrons. Prior to joining Opentrons in 2016, Pantea co-founded and directed the design team at Synbiota - a web-based DNA development and collaboration platform, paired with standardized genetic engineering kits for rapid DNA prototyping. She is inspired by the day to day hustle of scientists and enabling experiential advancements in lab oriented workflows and procedures. In her off time Pantea loves baking bread, marathon dancing, and listening to the Pet Shop Boys and Boards of Canada on heavy rotation.',
  },
  {
    name: 'Sanniti Pimpley',
    title: 'Software Engineer',
    img: require('./assets/sanniti.png').default,
    bio: 'Sanniti joined Opentrons in May 2018 and works primarily on the robot backend software and module firmware development. She enjoys building robots of all kinds: floor-printing, wing-flapping, crawling, and of course, pipetting ones. After completing her Bachelor’s in Electronics & Telecommunications Engineering, Sanniti received a Master’s from NYU ITP and is passionate about innovating using art and technology. When she’s not at Opentrons, you can find her exploring NYC, learning to dance and dreaming about creating her own Wizarding World.',
  },
  {
    name: 'Sakib Hossain',
    title: 'Junior Applications Engineer',
    bio: 'Coming soon!',
  },
  {
    name: 'Sarah Herrick',
    title: 'Recruiting Coordinator',
    bio: 'Coming soon!',
  },
  {
    name: 'Scott Hraska',
    title: 'Senior Operations Manager',
    bio: 'Coming soon!',
  },
  {
    name: 'Seth Foster',
    title: 'Senior Software Engineer',
    img: require('./assets/seth.png').default,
    bio: "Seth joined Opentrons as a Software Engineer in August 2018. He has a BS in Engineering from Swarthmore College. Seth's interests include hardware development, parks, and live music.",
  },
  {
    name: 'Shea Morgan',
    title: 'Account Research/Development Analyst',
    bio: 'Coming soon!',
  },
  {
    name: 'Skye Windsor',
    title: 'Account Manager',
    img: require('./assets/skye_windsor.png').default,
    bio: 'Skye joined the Opentrons family as an Account Manager in June 2019. She holds her BS in Biology from Santa Clara University and worked at the Stanford Center for Cognitive and Neurobiological Imaging as an undergrad. Skye has a deep passion for self expression through music and dance. Outside the office, you can find her in yoga class, playing intramural soccer, or furthering her reading addiction.',
  },
  {
    name: 'April Bard',
    title: 'Director of Human Resources',
    img: require('./assets/april.png').default,
    bio: 'April joined Opentrons in 2019 and leads the HR function. Outside of work, April enjoys sunshine, coffee and puppies.',
  },
  {
    name: 'Trisha Merriam',
    title: 'Senior Digital Marketing Manager',
    bio: 'Coming soon!',
  },
  {
    name: 'Taylor Goodman',
    title: 'Director of Hardware',
    img: require('./assets/taylor.png').default,
    bio: 'Prior to Opentrons Taylor led the mechanical engineering department at MakerBot. Early in his career he led the design and development of the worlds first consumer 3D printer and helped to shape the industry into where it is today. He is inspired about how the future of automation will transform the biomedical industry. From Westchester to UCLA to Shenzhen China, Taylor loves traveling the world and undertaking new challenges and adventures',
  },
]
