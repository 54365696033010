// @flow

import * as React from 'react'

import * as styles from './Team.module.css'

export type PhotoProps = {
  img: string,
  name: string,
  title: string,
  onClick: (event: React.SyntheticMouseEvent<>) => mixed,
}

export default function Photo(props: PhotoProps) {
  const {img, name, title, onClick} = props
  return (
    <>
      <div className={styles.photo}>
        <figure onClick={onClick}>
          <img className={styles.image} src={img} alt={name} />
          <figcaption className={styles.photo_container}>
            <span className={styles.photo_title}>{title}</span>
            <span className={styles.photo_name}>{name}</span>
            <br />
          </figcaption>
        </figure>
      </div>
    </>
  )
}
