// @ flow

import React from 'react'
import {Link} from 'gatsby'
import cx from 'classnames'

import {Button} from '../../ui-components'

import Photo from './Photo'
import {TEAM_DATA} from './team-data'
import Placeholder from './assets/placeholder.png'

import * as styles from './Team.module.css'

const ButtonProps = {
  to: '/jobs',
  outline: true,
  children: 'view current openings',
  Component: Link,
  className: styles.button,
}

export default function Team() {
  const [currentBio, setCurrentBio] = React.useState('')

  function loadBio(name) {
    const member = TEAM_DATA.find(element => element.name === name)
    setCurrentBio(member)
    window.scrollTo({
      top: 400,
    })
  }

  const orderedTeamMembers = [...TEAM_DATA].sort((A, B) =>
    A.name.localeCompare(B.name)
  )

  return (
    <>
      <div className={styles.container}>
        <div>
          <h2 className={styles.h2}>Join Our Team</h2>
          <h5 className={styles.h5}>
            We are constantly looking for world-class talent to help us bring
            affordable and easy-to-use automation to biologists. Whether you are
            an engineer, a scientist, or a designer, join Opentrons and help us
            transform how biological research is done.
          </h5>
          <Button {...ButtonProps} />
        </div>
        <div>
          <h3 className={styles.h3}>Our Team</h3>
          {currentBio && (
            <section className={styles.team_bios}>
              <div className={styles.bio_photo}>
                <img
                  className={cx(styles.current_img, {
                    [styles.with_placeholder]: !currentBio.img,
                  })}
                  src={currentBio.img || Placeholder}
                />
              </div>
              <div>
                <h6 className={styles.title}>{currentBio.title}</h6>
                <h4 className={styles.h4}>
                  {currentBio.name}
                  <br />
                </h4>
                <p
                  className={styles.p}
                  dangerouslySetInnerHTML={{__html: currentBio.bio}}
                />
              </div>
            </section>
          )}
          <div className={styles.team_container}>
            {orderedTeamMembers.map(team => {
              return (
                <Photo
                  {...team}
                  key={team.name}
                  img={team.img || Placeholder}
                  onClick={() => loadBio(team.name)}
                />
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
