// extracted by mini-css-extract-plugin
export var h2 = "Team-module--h2--ADVC7";
export var h3 = "Team-module--h3--SeaSt";
export var h4 = "Team-module--h4--F05-D";
export var h5 = "Team-module--h5--8zZ9Q";
export var p = "Team-module--p--rm8up";
export var container = "Team-module--container--DxEze";
export var button = "Team-module--button--K04U7";
export var photo = "Team-module--photo--K-i-0";
export var image = "Team-module--image--uoUXr";
export var photo_container = "Team-module--photo_container--b5mnh";
export var bio_photo = "Team-module--bio_photo--Bxw+p";
export var current_img = "Team-module--current_img--SR9Cq";
export var with_placeholder = "Team-module--with_placeholder--C1iyY";
export var team_container = "Team-module--team_container--P6PTG";
export var team_bios = "Team-module--team_bios--Dd70G";
export var title = "Team-module--title---Ztf1";
export var photo_title = "Team-module--photo_title--yJxYy";
export var photo_name = "Team-module--photo_name--4J2Ie";