import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import Team from '../components/Team'

const TeamPage = () => (
  <Page>
    <SEO
      title="Meet the Team | Opentrons Lab Automation $5,000"
      description="Lab automation should be affordable, easy to use, and open to customization. The more people that have access to powerful technology, the better."
    />
    <Team />
  </Page>
)

export default TeamPage
